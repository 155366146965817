import { getUrl } from '../../config';
import {
    BookOpen,
} from "react-feather";

const pagesSection = [
    {
        href: "/pages",
        icon: BookOpen,
        title: "Gestion",
        children: [
            {
                href: "/gestion/container",
                title: "Container",
            },
        ],
    },

];


const navItems = [
    {
        title: "Pages",
        pages: pagesSection,
    },
];

export default navItems;