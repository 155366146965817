import { getUrl, setUrl } from '../../config';
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Tooltip, Menu, MenuItem, IconButton, ListItemIcon } from "@mui/material";
const erpOptions = {
    Clavier: {
        logo: "http://127.0.0.1:3333/images/upload/society/" + "clavier.jpeg",
        name: "Clavier",
        url: 'https://api.clavier.ylinar.fr'
    },
    Aluzion: {
        logo: "http://127.0.0.1:3333/images/upload/society/" + "clavier.jpeg",
        name: "Aluzion",
        url: 'https://api.aluzion.ylinar.fr'
    },
    Jay: {
        logo: "http://127.0.0.1:3333/images/upload/society/" + "jay.jpeg",
        name: "Jay",
        url: 'https://api.jay.ylinar.fr'
    },
    Cybax: {
        logo: "http://127.0.0.1:3333/images/upload/society/" + "cybax.jpeg",
        name: "Cybax",
        url: 'http://cybax.ylinar.fr:3333'
    },
    Local: {
        logo: "http://127.0.0.1:3333/images/upload/society/" + "logo.png",
        name: "Local",
        url: 'http://127.0.0.1:3333'
    }
};

function NavbarErp() {
    const navigate = useNavigate();

    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleErpChange = async (erp) => {
        try {
            setUrl(erpOptions[erp].url);
            localStorage.setItem('selectedErp', erp);
            navigate("/auth/sign-in");
        } catch (error) {
            console.error("Erreur lors du changement d'erp :", error);
        }
        closeMenu();
    };

    return (
        <React.Fragment>
            <Tooltip title="Changer d'ERP">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                    size="large"
                >
                    <ManageAccountsIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
                style={{ justifyContent: "center" }}
            >
                {Object.keys(erpOptions).map((erp) => (
                    <MenuItem key={erp} onClick={() => handleErpChange(erp)}>
                        <ListItemIcon>
                            <img
                                src={erpOptions[erp].logo}
                                alt={erpOptions[erp].name}
                                style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                        </ListItemIcon>
                        {erpOptions[erp].name}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

export default NavbarErp;