import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome back to Ylinar": "Welcome back to Ylinar",
      "Sales Month": "Sales Month",
      "Month": "Month",
      "Total Earnings": "Total Earnings",
      "Pending Orders": "Pending Orders",
      "Total Revenue": "Total Revenue",
      "New Messages": "New Messages",
      "Show all messages": "Show all messages",
      "New Notifications": "New Notifications",
      "Show all notifications": "Show all notifications",
      "French": "French",
      "English": "English",
      "Languages": "Languages",
      "recherche" : "Search",
      "delete" : "Delete",
      "dashboard": "Dashboard",
      "gestion": "Management",
      "devis": "Quote",
      "commande": "Order",
      "bondelivraison": "Delivery Note",
      "bonsimple": "Simple Delivery Note",
      "bonmultiple": "Multiple Delivery Note",
      "facture": "Invoice",
      "factureauto": "Auto Invoice",
      "achat": "Purchase",
      "container": "Container",
      "reception": "Reception",
      "fichierdebase": "Base File",
      "clients": "Clients",
      "prospects": "Prospects",
      "matieres": "Materials",
      "articles": "Articles",
      "fournisseurs": "Suppliers",
      "stock": "Stock",
      "carrousel": "Carousel",
      "parametres": "Settings",
      "utilisateurs": "Users",
      "gestiondesdroits": "Rights Management",
      "modedereglement": "Payment Method",
      "termedelivraison": "Delivery Term",
      "termedepaiement": "Payment Term",
      "typedefacturation": "Billing Type",
      "tauxdetva": "VAT Rate",
      "operations": "Operations",
      "echeances": "Maturities",
      "devises": "Currencies",
      "gammes": "Ranges",
      "unitedeconditionnement": "Packaging Unit",
      "typedecondtionnement": "Packaging Type",
      "familledematieres": "Material Family",
      "famillearticle": "Article Family",
      "activites": "Activities",
      "imprimantes": "Printers",
      "exportquadratus": "Export Quadratus",
      "previsionnel": "Forecast",
      "exports": "Exports",
      "statscommandes": "Orders Statistics",
      "bl.preparationbl" : "Preparation of Delivery Notes",
      "bl.passerenfacturebl" : "Pass to Invoice Delivery Notes",
      "bl.voirfichierliesbl" : "View Linked Files Delivery Notes",
      "bl.voircommandelies" : "View Linked Orders Delivery Notes",
      "bl.quantitedubon" : "Quantity of the Note",
      "bl.ncommande" : "Order Number",
      "bl.nfacture" : "Invoice Number",
      "bl.valide" : "Validate",
      "bl.edite" : "Edit",
      "bl.bondelivraisonnumero" : "Delivery Note N°",
      "bl.commandenumero" : "Order N°",
      "bl.bondelivraisondisponible" : "Delivery Note Available",
      "bl.deplacepourchoisir" : "Move to choose",
      "bl.passageenfacture" : "Passage in Invoice",
      "bl.bondelivraisonaconvertir" : "Delivery Note to Convert",
      "bl.multiplebl" : "Multiple Delivery Note",
      "bl.passagedebonensimple" : "Passage of",
      "bl.bondelivraisonenfacture" : "Delivery Note in Invoice",
      "bl.convertirenfacture" : "Convert to Invoice",
      "bl.contenu" : "Content",
      "opportunite": "Opportunity",
      "opportunite.dupliquer" : "Duplicate",
      "opportunite.contact" : "Contact",
      "opportunite.passerendevis" : "Pass to Quote",
      "opportunite.passerenurgenceledevis" : "Pass to Urgent Quote",
      "opportunite.cloturer" : "Close",
      "opportunite.reouvrir" : "Reopen",
      "opportunite.etat" : "State",
      "opportunite.code" : "Code",
      "opportunite.datedecreation" : "Creation Date",
      "opportunite.createur" : "Creator",
      "opportunite.commercial" : "Salesman",
      "opportunite.provenance" : "Source",
      "opportunite.devis" : "Quote",
      "opportunite.nomduclient" : "Customer Name",
      "opportunite.createurdeopportunite" : "Opportunity Creator",
      "opportunite.montant" : "Amount",
      "opportunite.tempsatelier" : "Workshop Time",
      "opportunite.tempsclient" : "Customer Time",
      "opportunite.confiance" : "Confidence",
      "opportunite.fichierlies" : "Linked Files",
      "opportunite.nomdeentreprise" : "Company Name",
      "opportunite.adresse" : "Address",
      "opportunite.codepostal" : "Zip Code",
      "opportunite.ville" : "City",
      "opportunite.pays" : "Country",
      "opportunite.numerodetelephone" : "Phone Number",
      "opportunite.email" : "Email",
      "opportunite.nomcontact" : "Contact Name",
      "opportunite.fonction" : "Function",
      "opportunite.fixe" : "Fixed",
      "opportunite.portable" : "Mobile",
      "opportunite.informationclient" : "Customer Information",
      "opportunite.informationcontact" : "Contact Information",
      "opportunite.choixducontact" : "Contact Choice",
      "opportunite.chargementdesinformations" : "Loading Information ...",
      "devis.descriptionatelier" : "Workshop Description",
      "devis.passerencommande" : "Pass to Order",
      "devis.passerenurgenceledevis" : "Pass to Urgent Order",
      "devis.accessclient" : "Client Access",
      "devis.archive" : "Archive",
      "devis.matiere&article&operation" : "Material - Article - Operation",
      "devis.prixbrut" : "Gross Price",
      "devis.prixunitaire" : "Unit Price",
      "devis.quantite" : "Quantity",
      "devis.remise" : "Discount",
      "devis.prixtotal" : "Total Price",
      "devis.commande" : "Order",
      "devis.totalht" : "Total HT",
      "devis.totalttc" : "Total TTC",
      "devis.nom" : "Name",
      "devis.gamme" : "Ranges",
      "devis.pubrut" : "Gross Price",
      "devis.punet" : "Unit Price",
      "devis.remise%": "Discount %",
      "devis.punetremise": "Unit Price Discount",
      "devis.quantite" : "Quantity",
      "devis.tva" : "VAT",
      "devis.prixht" : "Price HT",
      "devis.attentioncecinestpasleprixremise" : "Attention, this is not the discounted price",
      "devis.referencedevis" : "Quote Reference",
      "devis.opportunitelies" : "Linked Opportunity",
      "devis.voirpdf" : "View PDF",
      "devis.adressedefacturation" : "Billing Address",
      "devis.adressedelivraison" : "Delivery Address",
      "devis.contact" : "Contact",
      "devis.email" : "Email",
      "devis.telephone" : "Phone",
      "devis.mobile" : "Mobile",
      "devis.ilnyapasdecontact" : "There is no contact",
      "devis.datedelivraison" : "Delivery Date",
      "devis.facturation" : "Billing",
      "devis.livraison" : "Delivery",
      "devis.choixducontact" : "Contact Choice",
      "devis.client" : "Client",
      "commande.accompte" : "Deposit",
      "commande.blslies" : "Linked Delivery Notes",
      "commande.pdfinternes" : "Internal PDFs",
      "commande.passerlacommandeenurgence" : "Pass the order in emergency",
      "commande.quantiteenvoye" : "Sent Quantity",
      "commande.datedelivraison" : "Delivery Date",
      "commande.datedecreation" : "Creation Date",
      "commande.quantitelivre" : "Quantity Delivered",
      "commande.referencecommande" : "Order Reference",
      "commande.creerunbondelivraison" : "Create a Delivery Note",
      "container.loan" : "Loan",
      "container.delete" : "Delete",
      "container.achat" : "Purchase",
      "container.conditionnement" : "Packaging",
      "container.quantite" : "Quantity",
      "container.quantitetotal" : "Total Quantity",
      "container.createur" : "Creator",
      "container.valide" : "Validate",
      "container.datearrive" : "Arrival Date",
      "container.achatid" : "Purchase ID",
      "container.palette" : "Palette",
      "container.nom" : "Name",
      "container.gamme" : "Ranges",
      "container.quantitecaisse" : "Quantity per Box",
      "container.quantitedejacontainer" : "Quantity already in container",
      "container.quantitedemande" : "Requested Quantity",
      "container.referencecontainer" : "Container Reference",
      "container.taillecontainer" : "Container Size",
      "container.fichierlies" : "Linked Files",
      "container.datedecreation" : "Creation Date",
      "container.datededepart" : "Departure Date",
      "container.dateestimearrive" : "Estimated Arrival Date",
      "container.usineport" : "Factory -> Port",
      "container.portfactory" : "Port -> Factory",
      "container.divers" : "Miscellaneous",
      "container.descriptiondivers1" : "Description of Miscellaneous 1",
      "container.descriptiondivers2" : "Description of Miscellaneous 2",
      "container.stockageport" : "Storage at Port",
      "container.assurance" : "Insurance",
      "container.taxes" : "Taxes",
      "container.cout" : "Cost",
      "container.totalcoutdesmatieres" : "Total Cost of Materials",
    
      "hold.2" : "(Hold 2 seconds)",
      "Account": "Account",
      "Profile": "Profile",
      "Sign out": "Sign out",
      "List of Material": "List of Material",
      "List of Article": "List of Article",
      "List of Customer": "List of Customer",

    },
  },
  fr: {
    translation: {
      "Welcome back to Ylinar": "Bon retour sur Ylinar",
      "Sales Month": "Vente du Mois",
      "Month": "Mois",
      "Total Earnings": "Revenu Total",
      "Pending Orders": "Commandes en attente",
      "Total Revenue": "Revenu Total",
      "New Messages": "Nouveaux Messages",
      "Show all messages": "Voir tous les messages",
      "New Notifications": "Nouvelles Notifications",
      "Show all notifications": "Voir toutes les notifications",
      "French": "Français",
      "recherche" : "Recherche",
      "delete" : "Supprimer",
      "dashboard": "Tableau de Bord",
      "gestion": "Gestion",
      "devis": "Devis",
      "commande": "Commande",
      "bondelivraison": "Bon de Livraison",
      "bonsimple": "Bon Simple",
      "bonmultiple": "Bon Multiple",
      "facture": "Facture",
      "factureauto": "Facture Auto",
      "achat": "Achat",
      "container": "Conteneur",
      "reception": "Réception",
      "fichierdebase": "Fichier de Base",
      "clients": "Clients",
      "prospects": "Prospects",
      "matieres": "Matières",
      "articles": "Articles",
      "fournisseurs": "Fournisseurs",
      "stock": "Stock",
      "carrousel": "Carrousel",
      "parametres": "Paramètres",
      "utilisateurs": "Utilisateurs",
      "gestiondesdroits": "Gestion des Droits",
      "modedereglement": "Mode de Règlement",
      "termedelivraison": "Terme de Livraison",
      "termedepaiement": "Terme de Paiement",
      "typedefacturation": "Type de Facturation",
      "tauxdetva": "Taux de TVA",
      "operations": "Opérations",
      "echeances": "Échéances",
      "devises": "Devises",
      "gammes": "Gammes",
      "unitedeconditionnement": "Unité de Conditionnement",
      "typedecondtionnement": "Type de Conditionnement",
      "familledematieres": "Famille de Matières",
      "famillearticle": "Famille d'Articles",
      "activites": "Activités",
      "imprimantes": "Imprimantes",
      "exportquadratus": "Export Quadratus",
      "previsionnel": "Prévisionnel",
      "exports": "Exports",
      "statscommandes": "Statistiques des Commandes",
      "bl.preparationbl" : "Préparation Bl",
      "bl.passerenfacturebl" : "Passer en Facture Bl",
      "bl.voirfichierliesbl" : "Voir Fichiers liés Bl",
      "bl.voircommandelies" : "Voir Commandes liées Bl",
      "bl.quantitedubon" : "Quantité du Bon",
      "bl.ncommande" : "N° Commande",
      "bl.nfacture" : "N° Facture",
      "bl.valide" : "Valide",
      "bl.edite" : "Edité",
      "bl.bondelivraisonnumero" : "Bon de Livraison N°",
      "bl.commandenumero" : "Commande N°",
      "bl.bondelivraisondisponible" : "Bon de Livraison Disponible",
      "bl.deplacepourchoisir" : "Déplacer pour choisir",
      "bl.passageenfacture" : "Passage en Facture",
      "bl.bondelivraisonaconvertir" : "Bon de Livraison à Convertir",
      "bl.multiplebl" : "Bon de Livraison Multiple",
      "bl.passagedebonensimple" : "Passage de",
      "bl.bondelivraisonenfacture" : "Bon de Livraison en Facture",
      "bl.convertirenfacture" : "Convertir en Facture",
      "bl.contenu" : "Contenu",
      "opportunite": "Opportunité",
      "opportunite.dupliquer" : "Dupliquer",
      "opportunite.contact" : "Contact",
      "opportunite.passerendevis" : "Passer en Devis",
      "opportunite.passerenurgenceledevis" : "Passer en Urgence le Devis",
      "opportunite.cloturer" : "Clôturer",
      "opportunite.reouvrir" : "Réouvrir",
      "opportunite.etat" : "État",
      "opportunite.code" : "Code",
      "opportunite.datedecreation" : "Date de Création",
      "opportunite.createur" : "Créateur",
      "opportunite.commercial" : "Commercial",
      "opportunite.provenance" : "Provenance",
      "opportunite.devis" : "Devis",
      "opportunite.nomduclient" : "Nom du Client",
      "opportunite.createurdeopportunite" : "Créateur de l'Opportunité",
      "opportunite.montant" : "Montant",
      "opportunite.tempsatelier" : "Temps Atelier",
      "opportunite.tempsclient" : "Temps Client",
      "opportunite.confiance" : "Confiance",
      "opportunite.fichierlies" : "Fichiers liés",
      "opportunite.nomdeentreprise" : "Nom de l'Entreprise",
      "opportunite.adresse" : "Adresse",
      "opportunite.codepostal" : "Code Postal",
      "opportunite.ville" : "Ville",
      "opportunite.pays" : "Pays",
      "opportunite.numerodetelephone" : "Numéro de Téléphone",
      "opportunite.email" : "Email",
      "opportunite.nomcontact" : "Nom du Contact",
      "opportunite.fonction" : "Fonction",
      "opportunite.fixe" : "Fixe",
      "opportunite.portable" : "Portable",
      "opportunite.informationclient" : "Information Client",
      "opportunite.informationcontact" : "Information Contact",
      "opportunite.choixducontact" : "Choix du Contact",
      "opportunite.chargementdesinformations" : "Chargement des Informations ...",
      "devis.descriptionatelier" : "Description Atelier",
      "devis.passerencommande" : "Passer en Commande",
      "devis.passerenurgenceledevis" : "Passer en Urgence la Commande",
      "devis.accessclient" : "Accès Client",
      "devis.matiere&article&operation" : "Matière / Article / Opération",
      "devis.archive" : "Archiver",
      "devis.prixbrut" : "Prix Brut",
      "devis.prixunitaire" : "Prix Unitaire",
      "devis.quantite" : "Quantité",
      "devis.remise" : "Remise",
      "devis.prixtotal" : "Prix Total",
      "devis.commande" : "Commande",
      "devis.totalht" : "Total HT",
      "devis.totalttc" : "Total TTC",
      "devis.nom" : "Nom",
      "devis.gamme" : "Gammes",
      "devis.pubrut" : "Prix Brut",
      "devis.punet" : "Prix Unitaire",
      "devis.remise%": "Remise %",
      "devis.punetremise": "Prix Unitaire Remise",
      "devis.quantite" : "Quantité",
      "devis.tva" : "TVA",
      "devis.prixht" : "Prix HT",
      "devis.attentioncecinestpasleprixremise" : "Attention, ceci n'est pas le prix remisé",
      "devis.referencedevis" : "Référence du Devis",
      "devis.opportunitelies" : "Opportunité liées",
      "devis.voirpdf" : "Voir PDF",
      "devis.adressedefacturation" : "Adresse de Facturation",
      "devis.adressedelivraison" : "Adresse de Livraison",
      "devis.contact" : "Contact",
      "devis.email" : "Email",
      "devis.telephone" : "Téléphone",
      "devis.mobile" : "Mobile",
      "devis.ilnyapasdecontact" : "Il n'y a pas de contact",
      "devis.datedelivraison" : "Date de Livraison",
      "devis.facturation" : "Facturation",
      "devis.livraison" : "Livraison",
      "devis.choixducontact" : "Choix du Contact",
      "devis.client" : "Client",
      "commande.accompte" : "Acompte",
      "commande.blslies" : "BLs liés",
      "commande.pdfinternes" : "PDF Internes",
      "commande.passerlacommandeenurgence" : "Passer la commande en urgence",
      "commande.quantiteenvoye" : "Quantité envoyée",
      "commande.datedelivraison" : "Date de Livraison",
      "commande.datedecreation" : "Date de Création",
      "commande.quantitelivre" : "Quantité Livré",
      "commande.referencecommande" : "Référence Commande",
      "commande.creerunbondelivraison" : "Créer un Bon de Livraison",
      "container.loan" : "Prêt",
      "container.delete" : "Supprimer",
      "container.achat" : "Achat",
      "container.conditionnement" : "Conditionnement",
      "container.quantite" : "Quantité",
      "container.quantitetotal" : "Quantité Totale",
      "container.createur" : "Créateur",
      "container.valide" : "Validé",
      "container.datearrive" : "Date d'Arrivée",
      "container.achatid" : "Achat ID",
      "container.palette" : "Palette",
      "container.nom" : "Nom",
      "container.gamme" : "Gamme",
      "container.quantitecaisse" : "Quantité Caisse",
      "container.quantitedejacontainer" : "Quantité déjà dans le conteneur",
      "container.quantitedemande" : "Quantité Demandée",
      "container.referencecontainer" : "Référence du conteneur",
      "container.taillecontainer" : "Taille du conteneur",
      "container.fichierlies" : "Fichiers liés",
      "container.datedecreation" : "Date de Création",
      "container.datededepart" : "Date de Départ",
      "container.dateestimearrive" : "Date d'Arrivée Estimée",
      "container.usineport" : "Usine -> Port",
      "container.portfactory" : "Port -> Usine",
      "container.divers" : "Divers",
      "container.descriptiondivers1" : "Description divers 1",
      "container.descriptiondivers2" : "Description divers 2",
      "container.stockageport" : "Stockage Port",
      "container.assurance" : "Assurance",
      "container.taxes" : "Taxes",
      "container.cout" : "Coût",
      "container.totalcoutdesmatieres" : "Coût Total des Matières",
      "hold.2" : "(Maintenir 2 secondes)",
      "English": "Anglais",
      "Languages": "Langues",
      "Account": "Compte",
      "Profile": "Profil",
      "Sign out": "Déconnexion",
      "List of Material": "Liste des Matières",
      "List of Article": "Liste des Articles",
      "List of Customer": "Liste des Clients",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});
